<template>
    <div class="row camp-amenity">
        <div class="col-md-12">
            <div class="col-md-5">
                <img :src="site.medium_image">
            </div>
            <div class="col-md-7">
                <h3>{{ site.campsite }}</h3>
                <p>{{ site.campsite_area.area_name }}</p>
                <p><strong>Sleeping Capacity:</strong> {{ site.campsite_limit }}</p>
            </div>
            <div class="col-md-12">
                <!-- eslint-disable vue/no-v-html -->
                <p
                    class="description"
                    v-html="site.description"
                />
                <p>
                    Contact the Scout Office for more information. <br>
                    Quincy: <a href="tel:2172240204">217.224.0204</a> <br>
                    Burlington: <a href="tel:3197548413">319.754.8413</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        site: {
            type: Object,
            required: true,
        },
        viewUrlFormat: {
            type: String,
            required: true,
        },
    },

    computed: {
        viewUrl () {
            return this.viewUrlFormat.replace(':id', this.site.id)
        },
    },
}
</script>
