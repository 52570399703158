<template>
    <div class="row camp-amenity">
        <div class="col-md-12">
            <div class="col-md-5">
                <img :src="site.medium_image">
            </div>
            <div class="col-md-7">
                <h3><a :href="viewUrl">{{ site.campsite }}</a></h3>
                <p>{{ site.campsite_area.area_name }}</p>
                <p><strong>Sleeping Capacity:</strong> {{ site.campsite_limit }}</p>
                <p><strong>First Day Cost:</strong> ${{ site.first_day_cost }}</p>
                <p><strong>Additional Days Cost:</strong> ${{ site.following_days_cost }}</p>
            </div>
            <div class="col-md-12">
                <!-- eslint-disable vue/no-v-html -->
                <p
                    class="description"
                    v-html="site.description"
                />
                <a
                    :href="viewUrl"
                    class="btn btn-default btn-lg"
                >
                    Book {{ site.campsite }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        site: {
            type: Object,
            required: true,
        },
        viewUrlFormat: {
            type: String,
            required: true,
        },
    },

    computed: {
        viewUrl () {
            return this.viewUrlFormat.replace(':id', this.site.id)
        },
    },
}
</script>
